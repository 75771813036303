import bain from '../assets/images/bain.png';
import carmax from '../assets/images/carmax.png';
import duke from '../assets/images/duke.png';
import facebook from '../assets/images/facebook.png';
import FreeMonthBBDO from '../assets/images/freemonth/institutions/BBDO.png';
import FreeMonthBCG from '../assets/images/freemonth/institutions/BCG.png';
import FreeMonthBlackRock from '../assets/images/freemonth/institutions/BlackRock.png';
import FreeMonthBooth from '../assets/images/freemonth/institutions/Booth.png';
import FreeMonthGSB from '../assets/images/freemonth/institutions/GSB.png';
import FreeMonthGitHub from '../assets/images/freemonth/institutions/GitHub.png';
import FreeMonthGoogle from '../assets/images/freemonth/institutions/Google.png';
import FreeMonthHarvard from '../assets/images/freemonth/institutions/Harvard.png';
import FreeMonthKV from '../assets/images/freemonth/institutions/KV.png';
import FreeMonthKellogg from '../assets/images/freemonth/institutions/Kellogg.png';
import FreeMonthLinkedIn from '../assets/images/freemonth/institutions/LinkedIn.png';
import FreeMonthMeta from '../assets/images/freemonth/institutions/Meta.png';
import FreeMonthMicrosoft from '../assets/images/freemonth/institutions/Microsoft.png';
import FreeMonthUtahJazz from '../assets/images/freemonth/institutions/UtahJazz.png';
import UPenn from '../assets/images/freemonth/institutions/uPenn.png';
import goldman from '../assets/images/goldman.png';
import harvardBIT from '../assets/images/harvard-bio-investment-team.jpg';
import harvardLaw from '../assets/images/harvard-law.jpg';
import harvard from '../assets/images/harvard.png';
import hbs from '../assets/images/hbs.png';
import jpMorgan from '../assets/images/jpmorgan.png';
import mckinseyCo from '../assets/images/mckinsey_co.jpg';
import menloCoaching from '../assets/images/menlocoaching.png';
import queensDefenders from '../assets/images/queensdefenders.png';
import quintPub from '../assets/images/quintpub.png';
import salesforce from '../assets/images/salesforce.png';
import stacyBlackman from '../assets/images/stacyblackman.png';
import stanfordGsb from '../assets/images/stanford-gsb.jpg';
import stanfordMedical from '../assets/images/stanford-medical.png';
import stanford from '../assets/images/stanford.png';
import stripe from '../assets/images/stripe.png';
import uTexas from '../assets/images/u-texas.png';
import ucla from '../assets/images/ucla.png';
import warburgPincus from '../assets/images/warburg-pincus.png';
import { type Coach } from '../components/common/CoachCard';
import { __DEV__, __STAGING__ } from '../utils/constants';

const stagingCoaches: Coach[] = [
  {
    slug: 'joe-tears',
    institutions: [
      {
        title: 'Facebook',
        sub: 'Product Manager',
        image: facebook,
      },
      {
        title: 'Harvard Business School',
        sub: 'MBA',
        image: hbs,
      },
    ],
  },
  {
    slug: 'jiashuo-wang',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'BA, Psychology',
        image: stanford,
      },
      {
        title: 'UCLA',
        sub: 'PhD, Clinical Psychology',
        image: ucla,
      },
    ],
  },
  {
    slug: 'haasin-farooq',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'B.A., Statistics + Economics',
        image: harvard,
      },
      {
        title: 'Harvard Bio Investment Team',
        sub: 'Co-President and CEO',
        image: harvardBIT,
      },
    ],
  },
  {
    slug: 'tanner-thelin',
    institutions: [
      {
        title: 'Bain & Company',
        sub: 'Associate Consultant',
        image: bain,
      },
      {
        title: 'Duke University',
        sub: 'Bachelor’s Degree',
        image: duke,
      },
    ],
  },
  {
    slug: 'john-koelliker',
    institutions: [
      {
        title: 'Stacy Blackman Consulting',
        sub: 'Stacy Blackman Consulting',
        image: stacyBlackman,
      },
      {
        title: 'Menlo Coaching',
        sub: 'Senior Consultant',
        image: menloCoaching,
      },
    ],
  },
];

const prodCoaches: Coach[] = [
  {
    slug: 'lola-agabalogun',
    institutions: [
      {
        title: 'Stripe',
        sub: 'Strategy & Product',
        image: stripe,
      },
      {
        title: 'McKinsey & Company',
        sub: 'Business Analyst',
        image: mckinseyCo,
      },
    ],
  },
  {
    slug: 'debby-couture',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'Director of Graduate Admissions',
        image: stanford,
      },
      {
        title: 'University of Texas Austin',
        sub: 'Sr. Strategic Planning Associate',
        image: uTexas,
      },
    ],
  },
  {
    slug: 'alice-li-6ca99619',
    institutions: [
      {
        title: 'Harvard School of Dental Medicine',
        sub: 'DMD, Dentistry',
        image: harvard,
      },
      {
        title: 'Quintessence Publishing USA',
        sub: 'Dental Illustrator',
        image: quintPub,
      },
    ],
  },
  {
    slug: 'karla-maganafigueroa',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'Master’s in Public Policy',
        image: harvard,
      },
      {
        title: 'J.P. Morgan',
        sub: 'Investment Banking Associate',
        image: jpMorgan,
      },
    ],
  },
  {
    slug: 'gaby-joseph',
    institutions: [
      {
        title: 'Stanford Medical School',
        sub: 'M.D.',
        image: stanfordMedical,
      },
      {
        title: 'Stanford G.S.B.',
        sub: 'MBA',
        image: stanfordGsb,
      },
    ],
  },
  {
    slug: 'anna-gee',
    institutions: [
      {
        title: 'Harvard Law School',
        sub: 'J.D.',
        image: harvardLaw,
      },
      {
        title: 'Queens Defenders',
        sub: 'Staff Attorney',
        image: queensDefenders,
      },
    ],
  },
  {
    slug: 'sreya-maram',
    institutions: [
      {
        title: 'Salesforce',
        sub: 'Associate Product Manager',
        image: salesforce,
      },
      {
        title: 'CarMax',
        sub: 'Associate Product Manager Intern',
        image: carmax,
      },
    ],
  },
  {
    slug: 'wil-eyi',
    institutions: [
      {
        title: 'Warburg Pincus',
        sub: 'Associate',
        image: warburgPincus,
      },
      {
        title: 'Goldman Sachs',
        sub: 'Investment Banking Analyst',
        image: goldman,
      },
    ],
  },
  {
    slug: 'neha-singh',
    institutions: [
      {
        title: 'Facebook',
        sub: 'Product Manager',
        image: facebook,
      },
      {
        title: 'Harvard Business School',
        sub: 'MBA',
        image: hbs,
      },
    ],
  },
  {
    slug: 'sachin-waikar',
    institutions: [
      {
        title: 'Stanford University',
        sub: 'BA, Psychology',
        image: stanford,
      },
      {
        title: 'UCLA',
        sub: 'PhD, Clinical Psychology',
        image: ucla,
      },
    ],
  },
  {
    slug: 'john-rho',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'B.A., Statistics + Economics',
        image: harvard,
      },
      {
        title: 'Harvard Bio Investment Team',
        sub: 'Co-President and CEO',
        image: harvardBIT,
      },
    ],
  },
  {
    slug: 'julia-mark',
    institutions: [
      {
        title: 'Bain & Company',
        sub: 'Associate Consultant',
        image: bain,
      },
      {
        title: 'Duke University',
        sub: 'Bachelor’s Degree',
        image: duke,
      },
    ],
  },
  {
    slug: 'andrew-pelletier',
    institutions: [
      {
        title: 'Stacy Blackman Consulting',
        sub: 'Stacy Blackman Consulting',
        image: stacyBlackman,
      },
      {
        title: 'Menlo Coaching',
        sub: 'Senior Consultant',
        image: menloCoaching,
      },
    ],
  },
];

const prodFreeMonthCoaches: Coach[] = [
  {
    slug: 'jialu-z',
    headline: 'Product Management Coach',
    institutions: [
      {
        title: 'Chicago Booth',
        sub: 'MBA',
        image: FreeMonthBooth,
      },
      {
        title: 'Meta',
        sub: 'Product Manager',
        image: FreeMonthMeta,
      },
    ],
  },
  {
    slug: 'cate-d',
    headline: 'Management Consulting Coach',
    institutions: [
      {
        title: 'Kellogg School of Management',
        sub: 'MBA + MS in Design Innovation',
        image: FreeMonthKellogg,
      },
      {
        title: 'Boston Consulting Group',
        sub: 'Consultant',
        image: FreeMonthBCG,
      },
    ],
  },
  {
    slug: 'lindsay-s-1',
    headline: 'Career Development Coach',
    institutions: [
      {
        title: 'BBDO',
        sub: 'Strategic Planner',
        image: FreeMonthBBDO,
      },
      {
        title: 'Google',
        sub: 'Business Development Manager',
        image: FreeMonthGoogle,
      },
    ],
  },
  {
    slug: 'ian-k-1',
    headline: 'MBA Coach',
    institutions: [
      {
        title: 'Stanford GSB',
        sub: 'MBA + MS',
        image: FreeMonthGSB,
      },
      {
        title: 'Khosla Ventures',
        sub: 'Chief of Staff',
        image: FreeMonthKV,
      },
    ],
  },
  {
    slug: 'brian-e',
    headline: 'Executive Coach',
    institutions: [
      {
        title: 'The Wharton School (UPenn)',
        sub: 'MBA',
        image: UPenn,
      },
      {
        title: 'Microsoft',
        sub: 'Manager, Sales Strategy and Business Development',
        image: FreeMonthMicrosoft,
      },
    ],
  },
  {
    slug: 'nirali-s',
    headline: 'Career Development Coach',
    institutions: [
      {
        title: 'Microsoft',
        sub: 'Program Manager 2',
        image: FreeMonthMicrosoft,
      },
      {
        title: 'GitHub',
        sub: 'Staff Technical Program Manager',
        image: FreeMonthGitHub,
      },
    ],
  },
  {
    slug: 'jonathan-k',
    headline: 'Career Development Coach',
    institutions: [
      {
        title: 'Harvard University',
        sub: 'Research Assistant',
        image: FreeMonthHarvard,
      },
      {
        title: 'BlackRock',
        sub: 'Analyst',
        image: FreeMonthBlackRock,
      },
    ],
  },
  {
    slug: 'andrew-m-2',
    headline: 'Sales Coach',
    institutions: [
      {
        title: 'Utah Jazz',
        sub: 'Corporate Executive',
        image: FreeMonthUtahJazz,
      },
      {
        title: 'LinkedIn',
        sub: 'Account Executive',
        image: FreeMonthLinkedIn,
      },
    ],
  },
];

export const FeaturedCoaches =
  __DEV__ || __STAGING__ ? stagingCoaches : prodCoaches;

export const FeaturedFreeMonthCoaches =
  __DEV__ || __STAGING__ ? stagingCoaches : prodFreeMonthCoaches;
